import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_contacts_organizations_modal_filter = _resolveComponent("contacts-organizations-modal-filter")!
  const _component_contacts_all_contacts_table = _resolveComponent("contacts-all-contacts-table")!
  const _component_tm_modal = _resolveComponent("tm-modal")!

  return (_openBlock(), _createBlock(_component_tm_modal, {
    id: "contactsOrganizationsTableModal",
    "modal-title": "eBay contacts",
    "exact-size": "1200px",
    "no-footer": ""
  }, {
    "modal-content": _withCtx(() => [
      _createVNode(_component_contacts_organizations_modal_filter, {
        search: _ctx.search,
        "onUpdate:search": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.search) = $event)),
        columns: _ctx.tableHeaders,
        "onUpdate:columns": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.tableHeaders) = $event)),
        selected: _ctx.selected,
        "show-filters": "",
        filters: _ctx.filters,
        "search-placeholder": "Search contacts",
        "changed-filters-count": _ctx.isDefault.changesCount,
        "onUpdate:radioValue": _cache[2] || (_cache[2] = ($event: any) => (_ctx.filters[$event.name].radioValue = $event.value)),
        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (_ctx.filters[$event.name].value = $event.value)),
        onReset: _ctx.reset
      }, null, 8, ["search", "columns", "selected", "filters", "changed-filters-count", "onReset"]),
      _createVNode(_component_contacts_all_contacts_table, {
        modelValue: _ctx.selected,
        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.selected) = $event)),
        headers: _ctx.tableHeaders,
        items: _ctx.filteredTableItems,
        search: _ctx.search,
        "item-key": "id",
        "items-name": "contacts",
        "show-selection": ""
      }, null, 8, ["modelValue", "headers", "items", "search"])
    ]),
    _: 1
  }))
}