
import { computed, defineComponent, ref } from 'vue'
import TmModal from '@/components/shared/modal/TmModal.vue'
import { useModes } from '@/compositions/modes'
import type { TableHeaders } from '@/definitions/shared/types'
import { getTableData } from '@/services/tableService'
import type { ContactList } from '@/definitions/contacts/lists/types'
import ContactsAllContactsTable from '@/components/pages/contacts/allContacts/ContactsAllContactsTable.vue'
import type { ContactLine } from '@/definitions/contacts/allContacts/types'
import ContactsOrganizationsModalFilter
  from '@/components/modals/contacts/organizations/table/ContactsOrganizationsModalFilter.vue'
import { useFilters } from '@/compositions/filters'
import { contactsFilter } from '@/definitions/shared/filters/filtersList/data'

export default defineComponent({
  name: 'ContactsTableModal',
  components: {
    ContactsOrganizationsModalFilter,
    ContactsAllContactsTable,
    TmModal,
  },
  setup() {
    const { isEmptyMode } = useModes()
    const search = ref('')
    const selected = ref<ContactList[]>([])
    const tableHeaders = ref<TableHeaders[]>([
      { text: 'Full name', value: 'full-name-slot' },
      { text: 'Email', value: 'email-slot' },
      { text: 'Phone', value: 'phone-slot' },
      { text: 'Type', value: 'type-slot' },
    ])
    const tableItems = ref<ContactLine[]>(getTableData('contactsAllContacts'))
    const filteredTableItems = computed<ContactLine[]>(() => {
      return tableItems.value.filter((item) => item.person.fullName.toLowerCase().includes(search.value.toLowerCase()))
    })
    const { filters, reset, isDefault } = useFilters(contactsFilter)

    return {
      reset,
      isDefault,
      filters,
      isEmptyMode,
      search,
      selected,
      tableHeaders,
      tableItems,
      filteredTableItems,
    }
  },
})
