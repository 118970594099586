import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, mergeProps as _mergeProps } from "vue"

const _hoisted_1 = { class: "d-flex align-center font-color-inherit font-weight-normal w100pr" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tm_person = _resolveComponent("tm-person")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_tm_country = _resolveComponent("tm-country")!
  const _component_highlight = _resolveComponent("highlight")!
  const _component_tm_avatar = _resolveComponent("tm-avatar")!
  const _component_tm_ellipsis = _resolveComponent("tm-ellipsis")!
  const _component_tm_status = _resolveComponent("tm-status")!
  const _component_contacts_all_contacts_dropdown = _resolveComponent("contacts-all-contacts-dropdown")!
  const _component_tm_table = _resolveComponent("tm-table")!

  return (_openBlock(), _createBlock(_component_tm_table, {
    headers: _ctx.headers,
    items: _ctx.items,
    "items-name": _ctx.itemsName,
    "show-selection": _ctx.showSelection,
    class: "contacts-table"
  }, {
    "body-cell-full-name-slot": _withCtx((props) => [
      (_openBlock(), _createBlock(_component_router_link, {
        key: props.row.person.fullName,
        to: { name: 'base.contacts.contactDetails', params: { id: props.row.id } },
        class: "d-flex align-center font-color-inherit font-weight-normal blue-on-hover w100pr"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_tm_person, {
            name: props.row.person.fullName,
            "avatar-url": props.row.person.avatar,
            "avatar-color": props.row.person.avatarColor,
            search: _ctx.search,
            "avatar-size": "small"
          }, null, 8, ["name", "avatar-url", "avatar-color", "search"])
        ]),
        _: 2
      }, 1032, ["to"]))
    ]),
    "body-cell-phone-slot": _withCtx((props) => [
      _createVNode(_component_tm_country, {
        country: {
          id: props.row.country.id,
          name: props.row.phone,
        },
        size: "xSmall"
      }, null, 8, ["country"])
    ]),
    "body-cell-email-slot": _withCtx((props) => [
      (_openBlock(), _createBlock(_component_router_link, {
        key: props.row.person.fullName,
        to: { name: 'base.contacts.contactDetails', params: { id: props.row.id } },
        class: "d-flex align-center font-color-inherit font-weight-normal blue-on-hover w100pr"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_highlight, {
            text: props.row.email,
            search: _ctx.search
          }, null, 8, ["text", "search"])
        ]),
        _: 2
      }, 1032, ["to"]))
    ]),
    "body-cell-organization-slot": _withCtx((props) => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_tm_avatar, {
          name: props.row.organization.organizationName,
          url: require(`@/mock/images/organizations/${props.row.organization.organizationImage}`),
          size: "small",
          color: "white",
          class: "mr-2"
        }, null, 8, ["name", "url"]),
        _createVNode(_component_tm_ellipsis, { class: "w100pr" }, {
          default: _withCtx(() => [
            _createVNode(_component_highlight, {
              text: props.row.organization.organizationName,
              search: _ctx.search
            }, null, 8, ["text", "search"])
          ]),
          _: 2
        }, 1024)
      ])
    ]),
    "body-cell-country": _withCtx((props) => [
      _createVNode(_component_tm_country, {
        size: "xSmall",
        country: {
          id: props.row.country.id,
          name: props.row.country.name,
        }
      }, null, 8, ["country"])
    ]),
    "body-cell-type-slot": _withCtx((props) => [
      _createVNode(_component_tm_status, _mergeProps(props.row.type, { search: _ctx.search }), null, 16, ["search"])
    ]),
    "body-cell-actions": _withCtx((props) => [
      _createVNode(_component_contacts_all_contacts_dropdown, {
        "contact-line": props.row
      }, null, 8, ["contact-line"])
    ]),
    _: 1
  }, 8, ["headers", "items", "items-name", "show-selection"]))
}