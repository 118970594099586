
import type { PropType } from 'vue'
import { defineComponent } from 'vue'
import TmDropdownItem from '@/components/shared/TmDropdownItem.vue'
import TmTableActionButton from '@/components/shared/table/TmTableActionButton.vue'
import TmDropdown from '@/components/shared/TmDropdown.vue'
import useContactsOpenModal from '@/compositions/contacts/useContactsOpenModal'
import type { ContactLine } from '../../../../definitions/contacts/allContacts/types'

export default defineComponent({
  name: 'ContactsAllContactsDropdown',
  components: {
    TmDropdown,
    TmTableActionButton,
    TmDropdownItem,
  },
  props: {
    contactLine: {
      type: Object as PropType<ContactLine>,
      required: true,
    },
  },
  setup() {
    const {
      openModalEditContact,
      openDeleteContactModal,
      openBlockContactModal,
    } = useContactsOpenModal()

    return {
      openModalEditContact,
      openDeleteContactModal,
      openBlockContactModal,
    }
  },
})
