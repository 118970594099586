import { resolveComponent as _resolveComponent, createVNode as _createVNode, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_top_filter_button = _resolveComponent("top-filter-button")!
  const _component_dropdown_filter_statuses = _resolveComponent("dropdown-filter-statuses")!
  const _component_dropdown_filter_radio = _resolveComponent("dropdown-filter-radio")!
  const _component_dropdown_filter_assignee = _resolveComponent("dropdown-filter-assignee")!
  const _component_filter_add = _resolveComponent("filter-add")!
  const _component_top_filter = _resolveComponent("top-filter")!

  return (_openBlock(), _createBlock(_component_top_filter, null, {
    "filter-line-left-default": _withCtx(() => [
      _createVNode(_component_top_filter_button, {
        text: "Send campaign",
        icon: "tmi-campaign"
      }),
      _createVNode(_component_top_filter_button, {
        text: "Add contacts",
        icon: "add"
      })
    ]),
    "filter-line-left-selected": _withCtx(() => [
      _createVNode(_component_top_filter_button, {
        text: "Edit",
        icon: "edit"
      }),
      _createVNode(_component_top_filter_button, {
        text: "Add note",
        icon: "tmi-add-note"
      }),
      _createVNode(_component_top_filter_button, {
        text: "Remove from orig",
        icon: "tmi-person-remove"
      }),
      _createVNode(_component_top_filter_button, {
        text: "Follow",
        icon: "person_add"
      }),
      _createVNode(_component_top_filter_button, {
        text: "Download",
        icon: "download"
      }),
      _createVNode(_component_top_filter_button, {
        text: "Block",
        icon: "block"
      }),
      _createVNode(_component_top_filter_button, {
        text: "Delete",
        icon: "delete"
      })
    ]),
    "filter-line-details": _withCtx(({ updateRadioValue, updateModelValue }) => [
      _createVNode(_component_dropdown_filter_statuses, {
        modelValue: _ctx.filters.contactStatusesFilter.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.filters.contactStatusesFilter.value) = $event)),
        options: _ctx.filters.contactStatusesFilter.options,
        name: "Contact type",
        "search-placeholder": "Search contact type",
        removable: false
      }, null, 8, ["modelValue", "options"]),
      _createVNode(_component_dropdown_filter_radio, _mergeProps({
        "model-value": _ctx.filters.tags.value
      }, _ctx.filters.tags, {
        name: "Tags",
        "onUpdate:radioValue": ($event: any) => (updateRadioValue('tags', $event)),
        "onUpdate:modelValue": ($event: any) => (updateModelValue('tags', $event))
      }), null, 16, ["model-value", "onUpdate:radioValue", "onUpdate:modelValue"]),
      _createVNode(_component_dropdown_filter_assignee, {
        modelValue: _ctx.filters.assignee.value,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.filters.assignee.value) = $event)),
        options: _ctx.filters.assignee.options,
        label: "Owner",
        "search-placeholder": "Search owner"
      }, null, 8, ["modelValue", "options"]),
      _createVNode(_component_dropdown_filter_radio, _mergeProps({
        "model-value": _ctx.filters.organizations.value
      }, _ctx.filters.organizations, {
        name: "Organizations",
        "onUpdate:radioValue": ($event: any) => (updateRadioValue('organizations', $event)),
        "onUpdate:modelValue": ($event: any) => (updateModelValue('organizations', $event))
      }), null, 16, ["model-value", "onUpdate:radioValue", "onUpdate:modelValue"]),
      _createVNode(_component_dropdown_filter_radio, _mergeProps({
        "model-value": _ctx.filters.countries.value
      }, _ctx.filters.countries, {
        name: "Country",
        "onUpdate:radioValue": ($event: any) => (updateRadioValue('countries', $event)),
        "onUpdate:modelValue": ($event: any) => (updateModelValue('countries', $event))
      }), null, 16, ["model-value", "onUpdate:radioValue", "onUpdate:modelValue"]),
      _createVNode(_component_dropdown_filter_radio, _mergeProps({
        "model-value": _ctx.filters.lastUpdated.value
      }, _ctx.filters.lastUpdated, {
        name: "Last updated",
        "onUpdate:radioValue": ($event: any) => (updateRadioValue('lastUpdated', $event)),
        "onUpdate:modelValue": ($event: any) => (updateModelValue('lastUpdated', $event))
      }), null, 16, ["model-value", "onUpdate:radioValue", "onUpdate:modelValue"]),
      _createVNode(_component_dropdown_filter_radio, _mergeProps({
        "model-value": _ctx.filters.dateCreated.value
      }, _ctx.filters.dateCreated, {
        name: "Date created",
        "onUpdate:radioValue": ($event: any) => (updateRadioValue('dateCreated', $event)),
        "onUpdate:modelValue": ($event: any) => (updateModelValue('dateCreated', $event))
      }), null, 16, ["model-value", "onUpdate:radioValue", "onUpdate:modelValue"]),
      _createVNode(_component_dropdown_filter_assignee, {
        modelValue: _ctx.filters.createdBy.value,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.filters.createdBy.value) = $event)),
        options: _ctx.filters.createdBy.options,
        label: "Created by",
        "search-placeholder": "Search user"
      }, null, 8, ["modelValue", "options"]),
      _createVNode(_component_filter_add, {
        items: _ctx.filters.addFilters
      }, null, 8, ["items"])
    ]),
    _: 1
  }))
}