
import type { PropType } from 'vue'
import { defineComponent } from 'vue'
import TmTable from '@/components/shared/table/TmTable.vue'
import TmPerson from '@/components/shared/TmPerson.vue'
import TmStatus from '@/components/shared/TmStatus.vue'
import TmCountry from '@/components/shared/TmCountry.vue'
import TmAvatar from '@/components/shared/TmAvatar.vue'
import TmEllipsis from '@/components/shared/TmEllipsis.vue'
import Highlight from '@/components/shared/Highlight.vue'
import ContactsAllContactsDropdown from '@/components/pages/contacts/allContacts/ContactsAllContactsDropdown.vue'
import type { TableHeaders } from '@/definitions/shared/types'

export default defineComponent({
  name: 'ContactsAllContactsTable',
  components: {
    TmCountry,
    TmStatus,
    TmTable,
    TmPerson,
    TmAvatar,
    TmEllipsis,
    Highlight,
    ContactsAllContactsDropdown,
  },
  props: {
    headers: {
      type: Array as PropType<TableHeaders[]>,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    itemsName: {
      type: String,
      required: true,
    },
    itemKey: {
      type: String,
      default: 'id',
    },
    showSelection: {
      type: Boolean,
      default: false,
    },
    search: {
      type: String,
      default: '',
    },
  },
})
