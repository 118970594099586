
import { defineComponent } from 'vue'
import TopFilter from '@/components/layout/topFilter/TopFilter.vue'
import TopFilterButton from '@/components/layout/topFilter/TopFilterButton.vue'
import DropdownFilterStatuses from '@/components/shared/filters/dropdown/DropdownFilterStatuses.vue'
import DropdownFilterRadio from '@/components/shared/filters/radio/DropdownFilterRadio.vue'
import DropdownFilterAssignee from '@/components/shared/filters/dropdown/DropdownFilterAssignee.vue'
import FilterAdd from '@/components/shared/filters/FilterAdd.vue'
import type { PropType } from 'vue'
import type { FiltersListType } from '@/definitions/shared/filters/types'

export default defineComponent({
  components: {
    TopFilter,
    TopFilterButton,
    DropdownFilterStatuses,
    DropdownFilterRadio,
    DropdownFilterAssignee,
    FilterAdd,
  },
  props: {
    filters: {
      type: Object as PropType<FiltersListType>,
    },
  },
})
